// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
//
// vite docs: 'https://vite-ruby.netlify.app/guide/rails')

console.log('Vite ⚡️ Rails')

/////////////////////////////////////////////////////////////////////////////////////
// Styling
/////////////////////////////////////////////////////////////////////////////////////

import "./application.scss";

/////////////////////////////////////////////////////////////////////////////////////
// Core JS
/////////////////////////////////////////////////////////////////////////////////////

import "@hotwired/turbo-rails";
import "../stimulus_setup";

// import 'trix'
// import '@rails/actiontext'

/////////////////////////////////////////////////////////////////////////////////////
// Service Worker
/////////////////////////////////////////////////////////////////////////////////////

// const userLoggedIn = document.body.getAttribute('data-user-logged-in') === 'true';

// if (userLoggedIn && 'serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/sw.js', { scope: '/' })
//       .then((registration) => {
//         console.log('ServiceWorker registered:', registration);

//         // Check for a new SW waiting to be active
//         if (registration.waiting) {
//           console.log('A new Service Worker is waiting.');
//           registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//         }

//         // Listen for updates to the Service Worker
//         registration.addEventListener('updatefound', () => {
//           const newWorker = registration.installing;
//           newWorker.addEventListener('statechange', () => {
//             if (newWorker.state === 'installed') {
//               if (navigator.serviceWorker.controller) {
//                 console.log("New content is available; please refresh.");
//               } else {
//                 console.log("Content is cached for offline use.");
//               }
//             }
//           });
//         });

//       }).catch(registrationError => {
//         console.log('Service worker registration failed:', registrationError);
//       });
//   });
// }
